export const isProduction = import.meta.env.PROD;

export const PRIMARY = '#eebc19';
export const SECONDARY = '#0074aa';

// Base 64
export const PREFIX_BASE_64 = 'data:image/png;base64,';

// Dark theme preference
export const KEY_PREFERENCE_DARK_THEME = 'DoYouPreferDarkTheme';
