// Some random colors
const colors = ['#3cc157', '#2aa7ff', '#30f5eb', '#fc9d0f', '#f85f36', '#f02c2c'];

const balls = [];

export const generateNiceBG = (numBalls) => {
  // remove previous balls
  const allBalls = document.getElementsByClassName('ball');
  [...allBalls].forEach((b) => b.remove());

  const mainDiv = document.getElementById('niceBGroot');

  for (let i = 0; i < numBalls; i++) {
    let ball = document.createElement('div');
    ball.classList.add('ball');
    ball.style.background = colors[Math.floor(Math.random() * colors.length)];
    ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
    ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
    ball.style.transform = `scale(${Math.random()})`;
    ball.style.width = `${Math.random()}em`;
    ball.style.height = ball.style.width;

    balls.push(ball);
    mainDiv?.append(ball);
  }

  // Keyframes
  balls.forEach((el, i) => {
    let to = {
      x: Math.random() * (i % 2 === 0 ? -11 : 11),
      y: Math.random() * 13,
    };

    el.animate(
      [{ transform: 'translate(0, 0)' }, { transform: `translate(${to.x}rem, ${to.y}rem)` }],
      {
        duration: (Math.random() + 1) * 2000,
        direction: 'alternate',
        fill: 'both',
        iterations: Infinity,
        easing: 'ease-in-out',
      }
    );
  });
};
