import { CssBaseline, useMediaQuery } from '@mui/material';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  createTheme,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import { createContext, useContext, useMemo, useState } from 'react';
import { KEY_PREFERENCE_DARK_THEME, PRIMARY, SECONDARY } from '../constantes';

// Exemples of custom components
const components = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'dashed', color: 'secondary' },
        style: {
          border: `4px dashed red`,
        },
      },
    ],
  },
};

const darkPalette = {
  mode: 'dark',
  primary: {
    main: PRIMARY,
    // contrastText: '#ffffff',
  },
  secondary: {
    main: SECONDARY,
  },
  background: {
    default: '#242424',
  },
};

const lightPalette = {
  mode: 'light',
  primary: {
    main: PRIMARY,
  },
  secondary: {
    main: SECONDARY,
  },
  background: {
    default: '#dbdbdb',
  },
};

const ThemeContext = createContext({});
export const useThemeContext = () => useContext(ThemeContext);

const ThemeProvider = ({ children }) => {
  // Get as initial value the computer (browser ?) theme preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // Save it locally
  const localPreferDark = localStorage.getItem(KEY_PREFERENCE_DARK_THEME);

  const [preferDark, setPreferDark] = useState(
    localPreferDark !== null ? localPreferDark === 'true' : prefersDarkMode
  );

  const theme = useMemo(
    () =>
      extendTheme(
        createTheme({
          palette: preferDark ? darkPalette : lightPalette,
          themeName: 'MyTheme',
          components,
        })
      ),
    [preferDark]
  );

  const toggleDarkTheme = () => {
    setPreferDark(!preferDark);
    localStorage.setItem(KEY_PREFERENCE_DARK_THEME, !preferDark);
  };

  return (
    <ThemeContext.Provider value={{ toggleDarkTheme, preferDark }}>
      {/* Allow to access the theme variable into CSS */}
      <CssVarsProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </CssVarsProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
