import { useRef, useEffect, useState } from 'react';

export const useInterval = (callback, delay = null) => {
  const savedCallback = useRef(callback);

  // update callback if it changes
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set interval
  useEffect(() => {
    // Called each tick
    const tick = () => {
      savedCallback.current();
    };
    // only if there's a delay
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useDebounce = (value, delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value only after delay
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      return () => {
        clearTimeout(timeoutId);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useSlowUpdateValue = (value, MAX_TIME = 2000, NB_MAX_UPDATE = 80, debug = false) => {
  if (isNaN(value)) {
    console.log("Warning : useSlowUpdateValue ne s'utilise qu'avec des int !");
  }
  const UPDATE_INTERVAL = MAX_TIME / NB_MAX_UPDATE;
  const [current, setCurrent] = useState(value || 0);
  const previousValue = useRef(value || 0);
  const originValue = useRef(value || 0);
  const timerId = useRef(null);

  const [step, setStep] = useState(0);
  const [delta, setDelta] = useState(0);

  useEffect(() => {
    if (step !== 0) {
      if ((step > 0 && current + step > value) || (step < 0 && current + step < value)) {
        clearTimeout(timerId.current);
        setStep(0);
        setDelta(0);
        originValue.current = value;
        setCurrent(value);
      } else {
        debug && console.log('augmente val de  = ', step);
        clearTimeout(timerId.current);
        timerId.current = setTimeout(
          () =>
            setCurrent((old) => {
              debug && console.log('setcurrent = ', old + step);
              return old + step;
            }),
          UPDATE_INTERVAL
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, step, UPDATE_INTERVAL, value]);

  useEffect(() => {
    debug && console.log('Value = ', value);
    debug && console.log('previousValue = ', previousValue.current);
    if (value !== previousValue.current) {
      previousValue.current = value;
      const deltaToPrint = value - originValue.current;
      const currentDelta = value - current;
      const nextStep = currentDelta / NB_MAX_UPDATE;
      debug && console.log('nextStep = ', nextStep);
      setStep(nextStep);
      setDelta(deltaToPrint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, NB_MAX_UPDATE, current]);

  const setImmediate = (immediate) => {
    clearTimeout(timerId.current);
    setStep(0);
    setDelta(0);
    previousValue.current = immediate;
    originValue.current = immediate;
    setCurrent(immediate);
  };

  return { current: Math.round(current), delta, setImmediate };
};

// Triggers the callback when there's a click outside of the ref parameter
export const useOutsideClick = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };

    // Bind the event listener
    document.body.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export const UNDO_KEY = 'CTRL+Z';
// Bind a keyPress event and execute the callback when the keyCodes has the one that has been pressed
export function useKeyPress(keyCodes = [], callback) {
  useEffect(() => {
    // Ne pas ajouter de listener si aucun keyCodes
    if (keyCodes.length === 0) {
      return;
    }
    const handler = (event) => {
      const { key, code } = event;
      if (keyCodes.includes(key) || keyCodes.includes(code)) {
        callback(event);
      }
      if (keyCodes.includes(UNDO_KEY) && event.ctrlKey && key === 'z') {
        callback(event);
      }
    };

    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [keyCodes, callback]);
}

// KONAMI (or Whatever) code callback
export const useKonamiCode = (
  callback,
  sequence = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ]
) => {
  const buffer = useRef([]);

  const keySequence = useCallback(
    (event) => {
      if (event.defaultPrevented) return;

      if (event.key === sequence[buffer.current.length]) {
        buffer.current = [...buffer.current, event.key];
      } else {
        buffer.current = [];
      }

      if (buffer.current.length === sequence.length) {
        const bufferString = buffer.current.toString();
        const sequenceString = sequence.toString();

        if (sequenceString === bufferString) {
          callback();
        }
      }
    },
    [callback, sequence]
  );

  useEffect(() => {
    document.addEventListener('keydown', keySequence);
    return () => document.removeEventListener('keydown', keySequence);
  }, [keySequence]);
};

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  useEffect(() => {
    const updateMousePosition = (ev) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};

export const useIsDocumentHidden = () => {
  const [isDocHidden, setIsDocHidden] = useState(document.hidden);

  useEffect(() => {
    const updateDocVisibility = () => {
      setIsDocHidden(document.hidden);
    };
    window.addEventListener('visibilitychange', updateDocVisibility);
    return () => {
      window.removeEventListener('visibilitychange', updateDocVisibility);
    };
  }, []);
  return isDocHidden;
};
